<!--
 * @Author: kevinzgai zhanggai_ok@126.com
 * @Date: 2023-11-27 14:37:26
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2023-12-04 16:20:14
 * @FilePath: \zyt-zlb-mobile-develop\src\views\cinemaAssistant\competitorCinema\boxOfficeDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div>
      <van-cell-group>
        <van-field
          label="当前影院: "
          :custom-style="{ color: 'red' }"
          style="color: aliceblue"
          :value="ourCinema.cinemaName"
          readonly
        />
        <!-- 选中竞对影院 -->
        <van-field
          label="竞对影院: "
          placeholder="请选择竞对影院"
          custom-style="color: aliceblue;"
          clickable
          readonly
          :value="competitorCinema.cinemaName"
        >
        </van-field>
      </van-cell-group>
    </div>
    <van-tabs color="#1989fa" :active="activeTab" @change="tabChange">
      <van-tab title="昨天" name="yesterday"></van-tab>
      <van-tab title="近7天" name="before7days"></van-tab>
      <van-tab title="近30天" name="before30days"></van-tab>
    </van-tabs>
    <div>
      <ve-table
        :scroll-width="900"
        :table-data="filmData"
        :max-height="maxHeight"
        :fixed-header="true"
        :columns="columns"
        :border-y="true"
        v-if="filmData.length > 0"
      ></ve-table>
      <div v-else>
        <van-empty description="暂无数据" />
      </div>
    </div>
    <van-popup
      v-model="showCompetitorCinema"
      round
      position="center"
      :style="{ width: '90%', height: '50%' }"
      @click-overlay="onClose"
    >
      <van-picker
        show-toolbar
        title="请选择竞对影院"
        value-key="cinemaName"
        :columns="competitorCinemaList"
        @cancel="onClose"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'

export default {
  data() {
    return {
      activeTab: 'before7days',
      // 当前影院
      ourCinema: {},
      // 竞对影院
      competitorCinema: {},
      cinemaName: '',
      // 表格最大高度
      maxHeight: 900,
      filmData: [],
      columns: [
        {
          title: '影片名称',
          fixed: 'left',
          field: 'filmName',
          key: 'filmName',
          width: 40,
        },
        {
          title: '平均票价',
          field: 'averagePrice',
          children: [
            {
              key: 'averagePrice-our',
              field: 'averagePrice',
              title: '当前影院',
              width: 25,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                // console.log({ row, column, rowIndex })
                return (
                  <div>
                    <div>
                       <span>{row.averagePrice.our}元</span>
                    </div>
                  </div>
                )
              },
            },
            {
              key: 'averagePrice-competitor',
              field: 'averagePrice',
              title: '竞对影院',
              width: 25,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.averagePrice.competitor}元</span>
                    </div>
                  </div>
                )
              },
            },
          ],
        },
        {
          title: '最低票价',
          field: 'lowestPrice',
          children: [
            {
              key: 'lowestPrice-our',
              field: 'lowestPrice',
              title: '当前影院',
              width: 25,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.lowestPrice.our}元</span>
                    </div>
                  </div>
                )
              },
            },
            {
              key: 'lowestPrice-competitor',
              field: 'lowestPrice',
              title: '竞对影院',
              width: 25,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.lowestPrice.competitor}元</span>
                    </div>
                  </div>
                )
              },
            },
          ],
        },
        {
          title: '最高票价',
          field: 'highestPrice',
          children: [
            {
              key: 'highestPrice-our',
              field: 'highestPrice',
              title: '当前影院',
              width: 25,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.highestPrice.our}元</span>
                    </div>
                  </div>
                )
              },
            },
            {
              key: 'highestPrice-competitor',
              field: 'highestPrice',
              title: '竞对影院',
              width: 25,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.highestPrice.competitor}元</span>
                    </div>
                  </div>
                )
              },
            },
          ],
        },
        {
          title: '累计票房',
          field: 'totalBoxOffice',
          children: [
            {
              key: 'totalBoxOffice-our',
              field: 'totalBoxOffice',
              title: '当前影院',
              width: 25,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.totalBoxOffice.our}元</span>
                    </div>
                  </div>
                )
              },
            },
            {
              key: 'totalBoxOffice-competitor',
              field: 'totalBoxOffice',
              title: '竞对影院',
              width: 25,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.totalBoxOffice.competitor}元</span>
                    </div>
                  </div>
                )
              },
            },
          ],
        },
      ],
      // 竞对影院列表
      competitorCinemaList: [],
      // 显示竞对影院列表
      showCompetitorCinema: false,
    }
  },
  mounted() {
    // 动态计算表格最大高度
    this.maxHeight = window.innerHeight - 100
    let { competitorCinema, ourCinema } = this.$route.query
    this.ourCinema = JSON.parse(ourCinema)
    this.competitorCinema = JSON.parse(competitorCinema)
    console.log({ competitorCinema: this.competitorCinema, ourCinema: this.ourCinema })
    // 获取当前影院名称
    // 填充竞对影院列表 并且默认选中第一个
    this.getCompetitorSession().then((res) => {
      // console.log('AAAAAAAAAAAAAAAAAA', res.find((item) => item.cinemaCode === id))
    })
    // // 获取当前影院名称
    // this.cinemaName = this.$route.query.cinemaName
    // // yesterdayData 数据填充1000条
    // this.CompetitorCinema = this.competitorCinemaList[0]
  },
  methods: {
    // 获取竞对影院
    getCompetitorCinema() {
      console.log(this.$route.query)
      const query = {
        UserCode: this.$store.state.userInfo.mobile,
        ourCinemaId: this.$route.query.id,
      }
      console.log('获取竞对影院')
      return new Promise((resolve, reject) => {
        zjApi
          .getCompetitorList(query)
          .then((res) => {
            console.log({ res })
            this.competitorCinemaList = res
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 获取影院竞对排片分析
    getCompetitorSession() {
      const query = {
        UserCode: this.$store.state.userInfo.mobile,
        ourCinemaId: this.ourCinema.id,
        competitorCinemaId: this.competitorCinema.id,
        datetype: this.activeTab,
      }
      console.log({query})
      console.log('获取影院竞对排片分析', query)
      return new Promise((resolve, reject) => {
        zjApi
          .getAnalysisPriceInfo(query)
          .then((res) => {
            console.log({ res })
            this.filmData = res
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * @description: 关闭竞对影院列表
     * @return {*}
     */
    onClose() {
      this.showCompetitorCinema = false
    },

    /**
     * @description: 确认选中竞对影院
     * @param {*} value 选中的竞对影院
     * @param {*} index 选中的竞对影院索引
     * @return {*}
     */
    onConfirm(value, index) {
      console.log({ value, index })
      this.CompetitorCinema = value
      this.showCompetitorCinema = false
      this.getCompetitorSession()
    },
    /**
     * @description: 切换tab
     * @param {*} tab
     * @return {*}
     */
    tabChange(tab) {
      console.log(tab)
      this.activeTab = tab
      this.getCompetitorSession()
    },
  },
}
</script>
<style scoped lang="less">
.my-cell {
  color: red;
}
</style>
